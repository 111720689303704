<template>
  <section>
    <div class="w-full mt-4 shadow p-2 rounded-md">
      <div class="w-full">
        <DataTable
            :value="medicamentosStore._medicamentos_tabla"
            dataKey="ItemCode"
            class="p-datatable-sm"
            editMode="cell"
            v-model:expandedRows="expandedRows"
            responsiveLayout="scroll">
            <template #empty>
              <p class="text-xs">
                No se encontraron artículos, ingrese uno con Ctrl + y, para iniciar.
              </p>
            </template>
            <Column :expander="true" headerStyle="width: 3em" >
              <template #header>
                <button v-if="expandedRows && expandedRows.length" @click="expandedRows = null"><i class="pi pi-angle-down"></i></button>
                <button v-else @click="expandedRows = medicamentosStore._medicamentos_tabla.filter(a => a.ItemCode)"><i class="pi pi-angle-right"></i></button>
              </template>
            </Column>
            <Column header="Código mipres" v-if="validarColumnasTabla('U_PHR_CodMipres')" class="text-xs" headerClass="text-xs"  style="min-width: 16rem; max-width: 18rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_CodMipres }}</p>
              </template>
            </Column>
            <Column header="Num. Direccionamiento" v-if="validarColumnasTabla('U_PHR_NumDirec')" class="text-xs" headerClass="text-xs"  style="min-width: 16rem; max-width: 18rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_NumDirec }}</p>
              </template>
            </Column>
            <Column header="Articulo" class="text-xs" headerClass="text-xs" style="min-width: 20rem">
              <template #body="slotProps">
                <div class="w-full flex gap-2">
                  <button v-tooltip.top="'Ver pendiente'" class="w-1/12" @click="abrirModalPendiente(slotProps.data)" v-if="slotProps.data.OpenCreQty"><i class="pi pi-exclamation-circle text-red-600"></i></button>
                  <div class="w-full">
                    <div class="flex gap-2 w-full">
                      <span class="flex gap-2 items-center w-full">
                        <div class="w-10/12">
                          {{ slotProps.data.ItemCode + ' - ' + slotProps.data.ItemName }}
                        </div>
                        <div class="w-2/12 flex justify-end gap-2">
                          <button v-tooltip.top="'Ver artículo'" @click="obtenerDetalleMedicamento(slotProps.data)">
                            <i class="pi pi-eye text-blue-600"></i>
                          </button>
                        </div>
                      </span>
                    </div>
                    <div class="flex gap-2">
                      <p class="font-bold">Stock total:</p><p>{{ slotProps.data.OnHandQty }}</p>
                      <p class="font-bold">Precio:</p><p class="font-bold text-green-600">{{ $h.formatCurrency(parseInt(slotProps.data.UnitPrice)) }}</p>
                      <p>
                        {{ slotProps.data.unidadSeleccionada.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </Column>
            <Column header="Cantidad" class="text-xs" headerClass="text-xs" style="min-width: 8rem; max-width: 10rem">
              <template #body="slotProps">
                <div class="gap-0 text-xs grid grid-cols-2">
                  <p class="font-bold">Ent.</p>
                  <p class="font-bold text-green-600">{{ slotProps.data.DelivrdQty }}</p>
                  <p class="font-bold">Pend.</p>
                  <p class="font-bold text-red-600">{{ slotProps.data.OpenCreQty }}</p>
                  <p class="font-bold">Total</p>
                  <p class="font-bold text-gray-600">{{ slotProps.data.Quantity }}</p>
                </div>
              </template>
            </Column>
            <Column header="Prec. total" v-if="validarColumnasTabla('PriceTotal')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                {{ $h.formatCurrency(slotProps.data.UnitPrice * slotProps.data.Quantity) }}
              </template>
            </Column>
            <Column header="Seg. Ent." v-if="validarColumnasTabla('U_PHR_SeguiEntrega')" class="text-xs" headerClass="text-xs" style="max-width: 8rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.SegEnt }}</p>
              </template>
            </Column>
            <Column header="No. Ent." v-if="validarColumnasTabla('U_PHR_NumEntregas')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_NumEntregas }}</p>
              </template>
            </Column>
            <Column header="Ex.Cuot" v-if="validarColumnasTabla('U_PHR_Exonerado') && ordenesStore._udf.porcentaje_cuota > 0" class="text-xs" headerClass="text-xs" style="max-width: 5rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_Exonerado }}</p>
              </template>
              <span class="text-red-600 italic">Este campo es requerido</span>
            </Column>
            <Column header="Cant. Pres." v-if="validarColumnasTabla('U_PHR_CtdPrescrita')" class="text-xs" headerClass="text-xs" style="max-width: 5rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_CtdPrescrita }}</p>
              </template>
            </Column>
            <Column header="Fec. Pres." v-if="validarColumnasTabla('U_PHR_FecPrescrip')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_FecPrescrip }}</p>
              </template>
            </Column>
            <Column header="Frec." v-if="validarColumnasTabla('U_PHR_Frecuencia')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <p>{{ slotProps.data.Frecuencia}}</p>
              </template>
            </Column>
            <Column header="D. tratam." v-if="validarColumnasTabla('U_PHR_DuraTratami')" class="text-xs" headerClass="text-xs">
              <template #body="slotProps">
                <p>{{ slotProps.data.DurTrata }}</p>
              </template>
            </Column>
            <Column header="Registro Médico/ Nombre Médico" v-if="validarColumnasTabla('U_PHR_RegMed')" class="text-xs" headerClass="text-xs" style="min-width: 15rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_RegMed ? slotProps.data.U_PHR_RegMed.name : '' }}</p>
              </template>
            </Column>
            <Column header="IPS Prestadora Servicio" v-if="validarColumnasTabla('U_PHR_IPSPrest')" class="text-xs" headerClass="text-xs" style="min-width: 15rem">
              <template #body="slotProps">
                {{ slotProps.data.U_PHR_IPSPrest ? slotProps.data.U_PHR_IPSPrest.NomIps : '' }}
              </template>
            </Column>
            <Column header="No. Auto." v-if="validarColumnasTabla('U_PHR_NumAutoriza')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_NumAutoriza }}</p>
              </template>
            </Column>
            <Column header="Fec. Auto." v-if="validarColumnasTabla('U_PHR_FecAutoriza')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_FecAutoriza }}</p>
              </template>
            </Column>
            <Column header="Fec. Prescrip. Mipres" v-if="validarColumnasTabla('U_PHR_FecPres')" class="text-xs w-min" headerClass="text-xs"  style="max-width: 8rem">
              <template #body="slotProps">
                <p>{{ slotProps.data.U_PHR_FecPres }}</p>
              </template>
            </Column>
            <Column header="Junta médica" v-if="validarColumnasTabla('U_PHR_JunMedi')" class="text-xs" headerClass="text-xs" style="max-width: 5rem">
              <template #body="slotProps">
                <p>
                  {{ slotProps.data.U_PHR_JunMedi }}
                </p>
              </template>
            </Column>
            <template #expansion="slotProps">
              <DataTable
                v-if="slotProps.data.StockMovements.length"
                :value="slotProps.data.StockMovements"
                responsiveLayout="scroll"
                editMode="cell"
                class="tablaLotes"
                :headerCheckboxToggleAllDisabled="true"
              >
                <Column header="Bodega" style="width: 5rem;" class="text-xs" headerStyle="background: white;" headerClass="bg-white">
                  <template #body="slotPropsChild">
                    <div class="flex gap-1">
                      <button v-if="slotPropsChild.data.BodegaNombre" @click="toggleBodega($event, slotPropsChild.data.BodegaNombre)"><i class="pi pi-info-circle text-xs text-green-600"></i></button>
                      <OverlayPanel ref="opBodega" :value="slotPropsChild.data.WhsCode">
                        <div class="w-full">
                          <p class="text-xs font-medium">Bodega: {{ infoBodegaMovStock }}</p>
                        </div>
                      </OverlayPanel>
                      {{ slotPropsChild.data.WhsCode }}
                    </div>
                  </template>
                </Column>
                <Column header="Información del lote" class="text-xs" headerStyle="background: white;" style="width: 28rem;">
                  <template #body="slotPropsChild">
                    <div class="flex gap-2">
                      <p class="font-bold">Lote:</p>
                      <p>{{ slotPropsChild.data.BatchNumber }}</p>
                      <p class="font-bold">Fec. venc.:</p>
                      <p>{{ slotPropsChild.data.FecVcto }}</p>
                      <p class="font-bold">Cant. Stock:</p>
                      <p>{{ slotPropsChild.data.cantstock }}</p>
                    </div>
                  </template>
                </Column>
                <Column field="Quantity" header="Cantidad entregada" class="text-xs" headerStyle="background: white;" headerClass="bg-white">
                  <template #body="slotPropsChild">
                    <div class="flex gap-2">
                      <p>{{ slotPropsChild.data.Quantity }}</p>
                      <p class="font-bold text-blue-600 bg-blue-200 rounded-sm p-1 italic" v-if="slotPropsChild.data.apartado">Este medicamento es apartado</p>
                    </div>
                  </template>
                </Column>
              </DataTable>
              <p class="font-bold text-xs" v-else>No existen lotes seleccionados</p>
            </template>
          </DataTable>
          <div v-if="medicamentosStore._medicamentos_tabla.length ? medicamentosStore._medicamentos_tabla.some(a => a.OpenCreQty) : false" class="w-full my-2">
            <button @click="abrirModalListadoMedicamentosPendientes" class="flex gap-2 text-blue-600">
              <p class="text-blue-600">Artículos pendientes</p>
              <i class="pi pi-external-link"></i>
            </button>
          </div>
      </div>
    </div>
    <Dialog headerClass="text-xs" header="Cantidad del artículo"
            v-model:visible="displayModalMedicamentoPendiente" :style="{width: '30vw'}" :modal="true">
      <p class="text-sm">Esta editando las cantidades del artículo {{ lotePendienteSeleccionado.ItemName }}</p>
      <div class="w-full grid grid-cols-2 gap-2 text-sm mt-4">
        <p>Entregado:</p>
        <p>{{ lotePendienteSeleccionado.DelivrdQty }}</p>
        <p>Pendiente:</p>
        <p>{{ lotePendienteSeleccionado.OpenCreQty }}</p>
        <p>Cantidad total:</p>
        <p class="font-bold">{{ lotePendienteSeleccionado.Quantity }}</p>
      </div>
    </Dialog>
    <Dialog headerClass="text-xs" header="Artículos pendientes" v-model:visible="displayModalMedicamentosPendientesListado" :style="{width: '30vw'}" :modal="true">
      <p class="text-sm mb-4">Listado de medicamentos pendientes al crear esta orden</p>
      <div v-if="listadoPendientesTotales.length">
        <div class="text-sm" v-for="(medicamento, i) in listadoPendientesTotales" :key="i">
          <div class="flex my-2 items-center">
            <p class="w-9/12">
              {{ medicamento.ItemCode + ' - ' + medicamento.ItemName }}
            </p>
            <div class="grid grid-cols-2 w-3/12">
              <p class="font-bold">{{ medicamento.OpenCreQty }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="font-bold">No cuentas con medicamentos pendientes</p>
      </div>
    </Dialog>
    <VerMedicamento />
  </section>
</template>
<script>
import { ref, computed, onMounted } from 'vue'
import { useMedicosStore } from '../../../../stores/medicos.store'
import { useIpsStore } from '../../../../stores/ips.store'
import { useBodegasStore } from '../../../../stores/bodegas.store'
import { useMedicamentosStore } from '../../../../stores/medicamentos.store'
import { useOrdenesStore } from '../../../../stores/ordenes.store'
import { usePacientesStore } from '../../../../stores/pacientes.store'
import { useMipresStore } from '../../../../stores/mipres.store'
import { useConfigGlobal } from '../../../../stores/config-ov.store'
import VerMedicamento from '../../../../components/verMedicamento.vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
export default {
  name: 'bodyPrimeVue',
  components: {
    VerMedicamento
  },
  setup () {
    // Servicios
    const route = useRoute()
    // Stores
    const _ipsStore = useIpsStore()
    const _medicosStore = useMedicosStore()
    const bodegasStore = useBodegasStore()
    const medicamentosStore = useMedicamentosStore()
    const ordenesStore = useOrdenesStore()
    const mipresStore = useMipresStore()
    const pacientesStore = usePacientesStore()
    const configGlobal = useConfigGlobal()
    // Referencias
    const show = ref(true)
    const medicamentoSeleccionado = ref('')
    const displayModalReemplazarMd = ref(false)
    const numerosDireccionamiento = ref([])
    const displayModalMedicamentosAgrupados = ref(false)
    const displayModalMedicamentoPendiente = ref(false)
    const displayModalMedicamentosPendientesListado = ref(false)
    const displayModalMedicamentosApartados = ref(false)
    const medicamentosAgrupados = ref([])
    const medicamentosAgrupadosIngresar = ref([])
    const conteoMedicamentosPendientes = ref(0)
    const loteSeleccionado = ref()
    const lotePendienteSeleccionado = ref()
    const listadoPendientesTotales = ref([])
    const medicamentoAReemplazar = ref()
    const unidadEditar = ref(null)
    const mdApartados = ref({})
    const apartadosSend = ref([])
    const op = ref()
    const infoBodegaMovStock = ref()
    const opBodega = ref()
    const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV
    const medicamentoCompletarAgrupados = ref({})
    const lineasLotesSeleccionados = ref([])
    // Data referencias
    const expandedRows = ref(null)
    const opcionesExonera = ref([
      {
        name: 'Exonera',
        id: '01'
      },
      {
        name: 'No exonera',
        id: '02'
      }
    ])
    const arrSiNo = ref([
      {
        name: 'Si',
        value: 'S'
      },
      {
        name: 'No',
        value: 'N'
      }
    ])
    // Computadas
    const rowData = computed(() => medicamentosStore._medicamentos_tabla)
    const bodegaSeleccionada = computed(() => bodegasStore._bodega_seleccionada)
    const listadoMedicos = computed(() => _medicosStore.listado_medicos)
    const listadoIps = computed(() => _ipsStore.listado_ips)
    const creandoOrden = computed(() => ordenesStore._creando_orden)
    const codigosMipres = computed(() => mipresStore._listado_codigos)
    const mostrarCalcularCuota = computed(() => ordenesStore._mostrar_calcular_cuota)
    const columnasTabla = computed(() => configGlobal._columnas_tabla)
    // Metodos
    const cantidadFaltante = () => {
      if (medicamentosAgrupadosIngresar.value.length) {
        let cantidad = conteoMedicamentosPendientes.value
        medicamentosAgrupadosIngresar.value.map(a => {
          cantidad -= a.cantidadAgg
        })
        return cantidad <= 0 ? 0 : cantidad
      }
      return conteoMedicamentosPendientes.value
    }
    const obtenerDetalleMedicamento = (medicamento) => {
      medicamentosStore.$patch((state) => {
        state.display_ver_medicamento = true
      })
      medicamentosStore.findByItemCode(medicamento.ItemCode)
    }
    const abrirModalPendiente = (medicamento) => {
      displayModalMedicamentoPendiente.value = true
      lotePendienteSeleccionado.value = { ...medicamento }
    }
    const cerrarModalPendiente = () => {
      displayModalMedicamentoPendiente.value = false
      lotePendienteSeleccionado.value = ''
    }
    const rowClass = (data) => {
      return [{ 'row-temp-bg': data.ItemCode === rowData.value[0].ItemCode }]
    }
    const validarColumnasTabla = (columna) => {
      if (columnasTabla.value.some(a => a.ColName === columna)) {
        return !columnasTabla.value.find(a => a.ColName === columna).campo_oculto
      } else {
        return false
      }
    }
    const abrirModalListadoMedicamentosPendientes = () => {
      displayModalMedicamentosPendientesListado.value = true
      listadoPendientesTotales.value = []
      medicamentosStore._medicamentos_tabla.map(a => {
        if (a.OpenCreQty) {
          listadoPendientesTotales.value.push({ ...a })
        }
      })
    }
    const cerrarModalListadoMedicamentosPendientes = () => {
      listadoPendientesTotales.value = []
      displayModalMedicamentosPendientesListado.value = false
    }
    const toggle = (event) => {
      op.value.toggle(event)
    }
    const toggleBodega = (event, data) => {
      infoBodegaMovStock.value = data
      opBodega.value.toggle(event)
    }
    // Activación buscador de medicamentos
    window.addEventListener(
      'keypress',
      (event) => {
        const keyName = event.key
        if (keyName === '\x19') {
          document.getElementById('inputElement').click()
        }
      },
      false
    )
    onMounted(() => {
      ordenesStore.listarSeguimientoEntregas()
      ordenesStore.listarDuracionesTratamiento()
      ordenesStore.listarFrecuencias()
      ordenesStore.resetData().then(() => {
        ordenesStore.find(route.params.id).then((data) => {
          configGlobal.obtenerColumnasTabla({ cardCode: ordenesStore._header.cliente.CardCode, motAutoriza: data.U_PHR_MotAutoriza })
        })
      })
    })
    return {
      show,
      rowData,
      medicamentoSeleccionado,
      displayModalReemplazarMd,
      dayjs,
      expandedRows,
      columnasTabla,
      arrSiNo,
      listadoMedicos,
      listadoIps,
      codigosMipres,
      numerosDireccionamiento,
      opcionesExonera,
      creandoOrden,
      bodegaSeleccionada,
      obtenerDetalleMedicamento,
      displayModalMedicamentosAgrupados,
      medicamentosAgrupados,
      medicamentosAgrupadosIngresar,
      conteoMedicamentosPendientes,
      loteSeleccionado,
      displayModalMedicamentoPendiente,
      displayModalMedicamentosApartados,
      lotePendienteSeleccionado,
      abrirModalPendiente,
      cerrarModalPendiente,
      displayModalMedicamentosPendientesListado,
      abrirModalListadoMedicamentosPendientes,
      listadoPendientesTotales,
      cerrarModalListadoMedicamentosPendientes,
      rowClass,
      medicamentoAReemplazar,
      mostrarCalcularCuota,
      unidadEditar,
      baseUrl,
      mdApartados,
      apartadosSend,
      op,
      toggle,
      ordenesStore,
      cantidadFaltante,
      medicamentoCompletarAgrupados,
      medicamentosStore,
      lineasLotesSeleccionados,
      pacientesStore,
      validarColumnasTabla,
      toggleBodega,
      opBodega,
      infoBodegaMovStock
    }
  }
}
</script>

<style>
  ::-webkit-scrollbar {
    height: 0.3rem;
    width: 0.3rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  .row-temp-bg {
    animation-duration: 3s;
    animation-name: slidein;
    animation-play-state: running;
  }
  .h-panel {
    max-height: 300px!important;
  }

  @keyframes slidein {
    from {
      background: rgb(91, 195, 255);
    }

    to {
      background: white;
    }
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
